<!-- Localized -->
<template class="modalContent">
  <modal-content ref="gap-modal" :title="(currentBlockIndex+1)+'. '+blockData.block_name" @hidden="$emit('onClose')
" :showFooter="false" width="500">
    <template #content>
      <div class="" v-for="(entity, idx) in blockData.entities"
        :key="idx + (blockData._id || blockData.multi_id || blockData.block_id)">
        <FormField 
        :section="section"
        :ref="getFieldRef(entity)" 
        :fieldData="entity" 
        :parentData="blockData" 
        :key="entity.field_id" 
        :path="[
          ...path,
          { field: 'field_fe_id', value: entity.field_fe_id },
          ]" 
          :isMulti="checkIfMulti(entity, blockData.entities)"
          :validations="validations ? validations[idx] : validations" 
          :previewMode="previewMode"
          :previewForm="previewForm"
          :linkedBlock="linkedBlock" 
          @addTimeline="addTimeline(entity)"
          @removeTimeline="removeTimeline" class="my-4" 
          :formAlreadySubmitted="formAlreadySubmitted"
          @haveBlockErrors="haveBlockErrors"
        />
      </div>
      <div class="flex justify-center">
        <small class="text-red-600 inline-block text-sm" v-if="errorInFields">{{ $t('form_builder.please_correct_all_errors') }}</small>
      </div>
      <Button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
        @click="saveBlock()">{{ $t('form_builder.save') }}</Button>
    </template>
  </modal-content>
</template>

<script>
import { EventBus } from "@/main.js";
import modalContent from "@shared/modal-content";
import fieldTypes from "../utils/field-types";
import FormField from "./formField.vue";
import store, { mutations } from "../store";

import {
  checkIfMulti,
} from "../utils";

export default {
  // name: "gap-modal",
  data() {
    return {
      fieldTypes,
      checkIfMulti,
      store,
      showAddModal: false,
      errorInFields: false,
      isSaveClicked: false,
      invalidFields: false,
    }
  }
  ,
  props: {
    blockData: {
      type: Object,
      require: true,
    },
    currentBlockIndex: {
      type: Number,
      default: 0,
    },
    // Used to determin if field can be copied. Is false when field is multi but can't be copied anymore:
    path: Array,
    validations: {
      type: Object,
      default: () => null,
    },
    // is used to preview form before creating it in an admin panel:
    previewMode: {
      type: Boolean,
      default: false
    },
    // is used to prview filled form before its submitting:
    previewForm: {
      type: Boolean,
      default: false
    },
    linkedBlock: {
      type: Object,
      default: null
    },
    isAdd: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showGapModal: {
      type: Boolean,
    },
    section:{
      type: Object,
      require:true
    },
    formAlreadySubmitted: {
      type: Boolean
    }

  },
  components: {
    modalContent,
    FormField
  },
  // watch: {
  //   showAddModal(val) {
  //     if (val) {
  //       this.showModal()
  //     }

  //   }
  // },
  mounted() {
    // this.hideModal();
    if (this.showGapModal) { this.showModal() }

  },
  computed: {
    gapGroup: {
      get() {
        return store.gap_values[this.blockData.block_id] || null;
      },
      set(val) {
        this.$set(store.gap_values, this.blockData.block_id, val);
      },
    },
  },
  methods: {
    handleCloseByCross(){
      this.$emit("onCross")
      this.$emit("onClose", this.blockData)
    },
    haveBlockErrors() {
      let errorBlock = this.blockData?.entities.filter(el => el.ui_error);
      if (errorBlock && errorBlock?.length && this.isSaveClicked) {
        this.errorInFields = true;
      } else {
        this.errorInFields = false;
      }
    },
    showModal() {
      this.checkBlockValidation(this.isSaveClicked);
      this.$refs["gap-modal"].showModal();

    },
    checkBlockValidation(isSaved) {
      this.blockData.entities = this.blockData?.entities?.map(fld => {
        fld.is_field_saved = isSaved;
          if(fld.visible && !fld?.render_conditionally && fld?.mandatory && isSaved) {
            if (fld?.value == '' || fld?.value == null) {
              this.invalidFields = true;
              fld.ui_error = true;
              fld.ui_error_msg = this.$t('form_builder.error_field_required');
            } else if (fld?.ui_error) {
              this.invalidFields = true;
            }
            else {
              this.invalidFields = false;
              fld.ui_error = false;
              fld.ui_error_msg = '';
            }
          } else if (fld?.render_conditionally && isSaved) {
            let parentField = this.blockData?.entities?.find(el => el?.field_id == fld?.condition?.field_id)
            if (parentField?.value == fld?.condition?.dependence_value && parentField?.field_id == fld?.condition?.field_id) {
              if(fld?.mandatory) {
                if (fld?.value == '' || fld?.value == null) {
                  fld.ui_error = true;
                  this.invalidFields = true;
                  fld.ui_error_msg = this.$t('form_builder.error_field_required');
                } else if(fld.field_type == "Signature") {
                  let signValue = fld?.value ? JSON.parse(fld?.value) : fld?.value
                  if(signValue && (signValue?.value == '' || !signValue?.value || signValue?.value == null)) {
                    fld.ui_error = true;
                    this.invalidFields = true;
                    fld.ui_error_msg = this.$t('form_builder.error_field_required');
                  }
                } else {
                  fld.ui_error = false;
                  this.invalidFields = false;
                  fld.ui_error_msg = '';
                }
              } else {
                fld.ui_error = false;
                this.invalidFields = false;
                fld.ui_error_msg = '';
              }
            } else {
              fld.ui_error = false;
              this.invalidFields = false;
              fld.ui_error_msg = '';
            }
          } else {
            fld.ui_error = false;
            this.invalidFields = false;
            fld.ui_error_msg = '';
          }
          return fld;
        }
      );
    },
    saveBlock() {
      this.isSaveClicked = true;
      this.$emit("isSaved");
      this.checkBlockValidation(this.isSaveClicked);
      this.haveBlockErrors();
      let fieldWithInvalidValue = this.blockData.entities?.filter(el => el.ui_error == true);
      if (fieldWithInvalidValue && fieldWithInvalidValue?.length > 0) {
        this.invalidFields = true;
      }
      if (this.invalidFields) {
        return
      } else {
        if(!this.isEdit){
          let addElemToLast = true;
          let previous = this.blockData;
          const getValues = () => {
          let values = {};
          previous.entities.map((el) => (values[el.field_id] = el.value));
          return values;
          }
          const entity = this.store.utils.copyEntity({
            entity: previous,
            list: this.section.entities,
            clone: true,
            values: getValues(),
            block_clone_id: this.blockData.block_id+'_'+this.blockData.block_fe_id+'_'+this.section.entities.length
          }, addElemToLast); 
          this.blockData = entity;
        }
        this.$emit("editTimeLine")
        this.$refs["gap-modal"].hideModal(); 
        this.isSaveClicked = false;
      }
      // if(this.isClone) {
      //   let addElemToLast = true;
      //   let previous = this.blockData;
      //   console.log("previous",previous)
      //   const getValues = () => {
      //   let values = {};
      //   previous.entities.map((el) =>
      //         (values[el.field_id] = el.value)
      //     );
      //   return values;
      //   }
      //   // const entity = this.copyEntity(previous, getValues(), addElemToLast); 
      //   const entity = this.store.utils.copyEntity({
      //     entity: previous,
      //     list: this.section.entities,
      //     clone: true,
      //     values: getValues(),
      //     block_clone_id: this.blockData.block_id+'_'+this.blockData.block_fe_id+'_'+this.section.entities.length
      //   }, addElemToLast); 
      //   console.log("entity",entity)
      //   this.blockData = entity;
      // }
    },
    canBeCopied() {
      return checkIfMulti(this.blockData, this.section.entities);
    },
    hideModal() {
      this.$emit('onClose')
      this.$refs["gap-modal"].hideModal();
    },
    getFieldRef(field) {
      if (this.getFieldConfig(field).customTemplate)
        return `form-field-${this.getFieldConfig(field).customTemplate}`
      else return `form-field-${this.getFieldType(field)}`
    },
    getFieldConfig(field) {
      return this.fieldTypes[this.getFieldType(field)] || this.fieldTypes[this.getFieldBaseType(field)];
    },
    getFieldBaseType(field) {
        return field?.field_base_type?.toLowerCase();
    },
    getFieldType(field) {
      return field?.field_type?.toLowerCase();
    },
    nextSection() {
      EventBus.$emit("nextSection");
    },
    addTimeline(field) {
      if (!this.gapGroup) return;

      let userInputDates = [];
      this.section.entities.forEach((entity) => {
        if (entity.block_id === this.blockData.block_id && entity.form_entity_type == 'BLOCK') {
          const dateRange = entity.entities
          ?.filter((ent) => ent.value && ent.field_type === "Date Range (Timeline)" && ent.gap_validation)
          .map(ent => {
                  const {from ,to } =JSON.parse(ent.value);
                  const startDate = from.split(" ")[0]; 
                  const endDate =  to.split(" ")[0];
                 return({ start: new Date(startDate), end: new Date(endDate), 
                  block: entity, field: ent })
              }
            );
          userInputDates.push(...dateRange);
        }
      });
      const timelines = this.store.utils.findGapPeriods(userInputDates);

      this.$set(this.gapGroup, "timeline", timelines);

      // remove previous record:
      this.removeTimeline();
      mutations.setFilledGap(field, this.blockData);
    },
    removeTimeline() {
      if (!this.gapGroup) return;
      const gapToRemove = this.gapGroup.timeline.find(
        (el) => el.block?.block_fe_id === this.blockData.block_fe_id
      );
      // reset gaps list if there is gap to remove:
      if (gapToRemove)
        this.gapGroup.timeline = this.gapGroup.timeline.filter(
          (el) => el.block?.block_fe_id !== this.blockData.block_fe_id
        );
    },
    onCopyEntity() {
      const entity = this.copyEntity();
      this.$nextTick(() => {
        const newBlockEl = document.getElementById(entity.block_fe_id);
        newBlockEl.scrollIntoView({ block: "start", behavior: "smooth" });
      });
      return entity;
    },

    copyEntity(previous = this.blockData, values = {}, addElemToLast) {
      return this.store.utils.copyEntity({
        entity: this.blockData,
        list: this.section.entities,
        values,
        previous,
        block_clone_id: this.blockData.block_id + '_' + this.blockData.block_fe_id + '_' + this.section.entities.length
      }, addElemToLast);
    },

  }
}
</script>

<style lang="scss" scoped>
.inputBox {
  border: 1px solid lightgray;
  padding: 13px;
  border-radius: 5px;
  margin-top: 14px;
}


::v-deep {

  .vm--modal {
    border-radius: 10px !important;
  }
  .modalContent__header h3 {
    word-wrap: anywhere;
  }
}
 
</style>