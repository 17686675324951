<!-- Localized -->
<template>
    <ModalContent
        ref="modal"
        :name="modalId"
        :title="$t('form_builder.field_log')"
        submitButtonText="OK"
        width="488"
        @hidden="$emit('close')"
        @close="$emit('close')"
        @submit="hideModal"
    >
        <template #content>
            <div
                v-if="loading"
                class="flex items-center justify-center p-4"
            >
                <Loader />
            </div>
            <template v-else>
                <Empty
                    v-if="!changelog?.length"
                    :text="$t('form_builder.no_records_for_the_selected_field')"
                    classList="text-lg"
                    :showIcon="false"
                    :showBtn="false"
                />
                <div
                    v-else
                    class="records flex flex-col w-full justify-stretch gap-4"
                >
                    <Record
                        v-for="(record, i) in changelog"
                        :key="record.id"
                        class="records__item"
                        :record="record"
                        :prev-record="changelog[i - 1]"
                    />
                </div>
            </template>
        </template>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import Record from "./components/Record.vue";
import Empty from "@shared/empty";
import Loader from "@shared/loader";
import { v4 as uuidv4 } from 'uuid';

export default {
    components: {
        ModalContent,
        Empty,
        Record,
        Loader,
    },
    props: {
        loading: Boolean,
        changelog: Array,
    },
    data() {
        return {
            modalId: uuidv4(),
        };
    },
    methods: {
        showModal() {
            this.$refs["modal"].showModal();
        },
        hideModal() {
            this.$refs["modal"].hideModal();
        },
    },
};
</script>

<style lang="scss" scoped>
</style>